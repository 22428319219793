import React from "react";
import ReactDOM from "react-dom";
import "./css/styles.css";
import "./css/welcome.css";
import "./css/footer.css";
import "./css/contact.css";
import "./css/project.css";
import "./css/about.css";
import "./css/navbar.css";
import "./css/preloaded.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
